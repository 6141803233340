import { IEmploymentHistory } from 'data-transfers/dto'

import { ISkill } from 'validators/new/skill.validator'

import orderExperience from './orderExperience'

export interface ISkillValue extends Omit<ISkill, 'value' | 'label'> {
  value?: string
  label?: string
}

export interface SkillDates extends ISkillValue {
  years: number
  startDate: Date
  endDate: Date
}

export interface IGetEmploymentHistoryDto
  extends Omit<
    IEmploymentHistory,
    'endDate' | 'skills' | 'description' | 'id'
  > {
  id?: string | null
  endDate: string | null
  description?: string | null
  skills: ISkillValue[]
}

export type SkillDateTuple = [Date, Date][]

function getYear(startDate: Date, endDate: Date): number {
  if (typeof startDate === 'string' || typeof endDate === 'string') return 0
  const start = startDate.getTime()
  const end = endDate.getTime()
  return (end - start) / 31536000000
}

function getFlat(arr: SkillDates[][]) {
  return arr.reduce((acc, val) => acc.concat(val), [])
}

function groupSkills(arr: SkillDates[]) {
  const grouped: { [x: string]: SkillDates[] } = {}
  arr.forEach((item) => {
    if (!item.skillId) return
    if (!grouped[item.skillId]) {
      grouped[item.skillId] = [item]
    } else {
      grouped[item.skillId]?.push(item)
    }
  })
  return grouped
}

function getTimelines(timelines: SkillDateTuple, skill: SkillDates) {
  const timelineLength = timelines.length
  let t: [Date, Date] = timelines[timelineLength - 1] ?? [
    new Date(),
    new Date()
  ]
  if (!t[0] || !t[1]) return []
  const ts = t[0].getTime()
  const te = t[1].getTime()
  const ss = skill.startDate.getTime()
  const se = skill.endDate.getTime()
  if (ts <= ss && te >= se) {
    return [...timelines]
  } else if (ts >= ss && te <= se) {
    // UPDATE CURRENT
    t = [skill.startDate, skill.endDate]
    timelines[timelineLength - 1] = t
    return [...timelines]
  } else if (
    (te <= ss && te <= se && ts <= se && ts <= ss) ||
    (ts >= ss && ts >= se && te >= se && te >= ss)
  ) {
    // NEW TIMELINE
    timelines.push([skill.startDate, skill.endDate])
  } else {
    if (ts >= ss && te >= se && ts <= se) {
      // NEW START DATE
      t[0] = skill.startDate
    }
    if (te <= se && ts >= ss && te >= ss) {
      // NEW END DATE
      t[1] = skill.endDate
    }
    timelines[timelineLength - 1] = t
  }
  return [...timelines]
}

function getYears(arr: { [x: string]: SkillDates[] }) {
  const skillsWithYears: SkillDates[] = []
  let timelines: SkillDateTuple = []
  Object.keys(arr).forEach((skillId) => {
    const skillGroup: SkillDates[] | undefined = arr[skillId]
    if (
      skillGroup?.length === 1 &&
      skillGroup[0]?.startDate &&
      skillGroup[0]?.endDate
    ) {
      skillsWithYears.push({
        ...skillGroup[0],
        years: getYear(skillGroup[0]?.startDate, skillGroup[0]?.endDate)
      })
    } else if (
      skillGroup &&
      skillGroup?.length > 0 &&
      skillGroup[0]?.startDate &&
      skillGroup[0]?.endDate
    ) {
      timelines = [[skillGroup[0].startDate, skillGroup[0].endDate]]
      for (let i = 1; i < skillGroup.length; i += 1) {
        timelines = getTimelines(timelines, skillGroup[i] as SkillDates)
      }
      let years = 0
      timelines.forEach((t) => {
        if (t[0] && t[1]) {
          years += getYear(t[0], t[1])
        }
      })
      years = Math.ceil(years)
      skillsWithYears.push({
        ...skillGroup[0],
        years
      })
    }
  })
  return skillsWithYears
}

const getSkillList = (
  employmentHistory?: IGetEmploymentHistoryDto[]
): SkillDates[] => {
  // Create SINGLE ARRAY OF SKILLS
  if (!employmentHistory) return []
  const orderedHistory = orderExperience(employmentHistory)
  if (!orderedHistory) return []
  const skillsAll = orderedHistory.map((e) =>
    e.skills.map((skill) => ({
      ...skill,
      years: 0,
      skillId: skill.skillId || (skill.id ? skill.id : ''), // fix between GET skills and new skills
      startDate: e.startDate ? new Date(e.startDate) : new Date(),
      endDate: e.endDate ? new Date(e.endDate) : new Date()
    }))
  )
  // FLATTEN ARRAY
  const skillsFlat = getFlat(skillsAll)
  // const skillsFlat: SkillDates[] = skillsAll.flatMap((item) => item)
  // GROUP SKILLS BY ID
  const skillsGrouped = groupSkills(skillsFlat)
  // GET YEARS AND DEGROUP
  const skillsWithYears = getYears(skillsGrouped)
  // sort skills descending by years
  const skillsSorted = skillsWithYears.sort(
    (a: { years: number }, b: { years: number }) =>
      a.years && b.years && a.years > b.years ? -1 : 1
  )
  return skillsSorted
}
export const formatMatchSkillLabel = (
  skill: string,
  years: number | null,
  maxSkillLength: number,
  resizingEnable = true
) => {
  if (skill?.length > maxSkillLength && resizingEnable) {
    skill = skill.slice(0, maxSkillLength - 3).concat('...')
  }
  return formatSkillLabel(skill, years)
}
export const formatSkillLabel = (
  skill: string | null,
  years: number | null
) => {
  if (skill && years !== null) {
    return `${skill} ${years < 1 ? '<1' : years > 5 ? '5+' : years}`
  }

  return (
    skill ??
    (years !== null ? (years < 1 ? '<1' : years > 5 ? '5+' : `${years}`) : '')
  )
}

export default getSkillList
